<template>
    <section id="contato">
        <div class="container">
            <b-row class="text-center">
                <b-col class="mb-5 mt-5">
                    <h2 class="mb-1 text-5 text-uppercase" style="padding-top: 35rem;">Fale comigo</h2>
                    <a href="https://github.com/MatheusNoguerol" target="_blank"><span class="text-primary text-4 mr-2"><i class="fab fa-github"></i></span></a>GitHub
                    <a href="https://www.linkedin.com/in/matheus-noguerol-7628a6253/" target="_blank"><span class="text-primary text-4 mr-2"><i class="fab fa-linkedin"></i></span></a>Linkedin <br>
                </b-col>
            </b-row>

            <b-row class="text-center">
                <b-col lg="4">
                    <p class="text-3 mb-4"><span class="text-primary text-4 mr-2"><i class="fas fa-map-marked-alt"></i></span> Rio de Janeiro / RJ </p>
                </b-col>

                <b-col lg="4">
                    <p class="text-3 mb-1"><span class="text-primary text-4 mr-2"><i class="fas fa-phone"></i></span> (21) 96523-0293 (Whatsapp)</p>
                </b-col>

                <b-col lg="4">
                    <p class="text-3 mb-4"><span class="text-primary text-4 mr-2"><i class="fas fa-envelope"></i></span> matheusnoguerol15@gmail.com</p>
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    
                </b-col>
            </b-row>
        </div>
    </section>
</template>
      
<script>
    export default {
    name: 'LandingContact',
        methods: {

        }
    }
</script>
    
<style scoped>

</style>
      